import React from 'react';

export default function GpsGateMainMenu() {
	return <header id='header'>
		<div className='container'>
			<div className='four columns'>
				<div className='logo'><a href='https://gpsgate.com'><img
					src='/gpsgate-logo.svg' id='img-logo'
					alt='logo'/></a>
				</div>
			</div>
			<nav id='nav-wrap' className='nav-wrap1 twelve columns'>
				<div id='menu-icon'>
					<i className='fa-navicon'></i> Menu - <span className='mn-clk'>Navigation</span>
				</div>
				<ul id='nav'>
					<li>
						{
							// eslint-disable-next-line
						}<a href='#'>Products</a>
						<ul>
							<li><a href='https://gpsgate.com/gpsgate-server'>GpsGate Server</a></li>
							<li><a href='https://gpsgate.com/gpsgate-splitter'>Splitter <small>(Former Client)</small></a></li>
							<li><a href='https://gpsgate.com/fleet-app'>Fleet app</a></li>
							<li><a href='https://gpsgate.com/tracker-app'>Tracker app</a></li>
						</ul>
					</li>
					<li>
						{
							// eslint-disable-next-line
						}<a href='#'>Resources</a>
						<ul>
							<li><a href='https://support.gpsgate.com/hc/en-us/categories/360000321413'>Developer docs</a></li>
							<li><a href='https://gpsgate.com/releases'>Release notes</a></li>
							<li><a href='https://gpsgate.com/blog'>Blog</a></li>
							<li><a href='https://gpsgate.com/partners'>Partners</a></li>
							<li><a href='https://gpsgate.com/devices'>Supported devices</a></li>
						</ul>
					</li>
					<li>
						{
							// eslint-disable-next-line
						}<a href='#'>Company</a>
						<ul>
							<li><a href='https://gpsgate.com/contact'>Contact us</a></li>
							<li><a href='https://gpsgate.com/about'>About</a></li>
							<li><a href='https://jobs.gpsgate.com/'>Careers</a></li>
							<li><a href='https://gpsgate.com/legal'>Legal</a></li>
						</ul>
					</li>
					<li><a href='https://support.gpsgate.com'>Support</a></li>
				</ul>
			</nav>
		</div>

	</header>

}
