import React from 'react';

export default function GpsGateTopNav() {
	return <section className='top-bar'>
		<div className='container'>
			<div className='top-links' style={{float: 'right'}}>
				<a href='https://gpsgate.com/blog'><i className='fa-comment'></i>&nbsp; Blog</a> |
				<a href='https://jobs.gpsgate.com'><i className='fa-users'></i>&nbsp; Jobs</a> 
			</div>
		</div>
	</section>
}
