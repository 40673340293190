import React from 'react';

export default function Title({children}) {
	return <section className='blox'>
		<article>
			<div className='container'>
				<div className='sixteen columns alignleft'>
					<h2 className='extr'>
						{children}
					</h2>
				</div>
			</div>
		</article>
	</section>
}
