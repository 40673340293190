import React from 'react';

export default function Submitted({secureKey}) {
	if (!secureKey) {
		return <></>
	}

	return <>
		<p className='App-intro'>
			Your information has been submitted.
		</p>
		<p className='App-intro'>
			You can use the key below to communicate with GpsGate Support about your login details
		</p>
		<code>
			{secureKey}
		</code>
	</>
}
