import React, {useState} from 'react';
import axios from 'axios';

export default function SecureForm({setSecureKey}) {
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState('');
	const [state, setState] = useState({
		email: '',
		ticketId: '',
		data: ''
	});

	function handleInputChange(event) {
		const value = event.target.value;
		const name = event.target.name;
		setState(state => ({
			...state,
			[name]: value
		}));
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setUploading(true);

		const response = await axios.post(process.env.REACT_APP_API_URL + '/put', {
			email: state.email,
			ticketId: state.ticketId,
			data: state.data
		});

		if (response.data.errorMessage) {
			setError('Failed');
			setSecureKey('');
			return;
		}

		setSecureKey(response.data);
		setError('');
		setState({
			email: '',
			ticketId: '',
			data: '',
		});
	}

	const formActive = !uploading && !!state.data && !!state.email && !!state.ticketId;
	return <div>
		<div className='eight columns'>
			<div className='contact-form'>
				<div className='clr'/>
				<br/>
				<form onSubmit={handleSubmit}>
					<h5>What is your Email?</h5>
					<input name='email' type='email' value={state.email} className='txbx'
						onChange={handleInputChange} required/><br/>
					<h5>What's your ticket <strong>number</strong>?</h5>
					<input name='ticketId' type='number' value={state.ticketId}
						className='txbx'
						onChange={handleInputChange} required/><br/>
					<div className='erabox'>
						<h5>Sensitive login details</h5>
						<textarea name='data' rows='5' value={state.data}
							className='txbx era'
							onChange={handleInputChange} required/><br/>
						<button type='submit' className={formActive ? 'skyblue' : 'gray'} disabled={!formActive}>
							<i className='fa-lock'/> Securely submit
						</button>
						<div id='spanMessage'>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div className='seven columns offset-by-one'>
			<h5>Sending credentials over unsecured channels like unencrypted email or websites
				is unsafe and leaves your server vulnerable.</h5><br/>
			<h5>Use our secure page to submit your login information to GpsGate Support.</h5>
			<br/>
			<p>
				{error && <div>
					<hr className='boldbx'/>
					<p className='App-intro'>
						Your submission has failed. Please make sure that all the fields
						are filled and try again.
					</p>
				</div>
				}
			</p>
		</div>
	</div>
}
