import React, {useState} from 'react';
import './App.css';
import GpsGateTopNav from './components/gpsgate/GpsGateTopNav';
import GpsGateMainMenu from './components/gpsgate/GpsGateMainMenu';
import Title from './components/sections/Title';
import MainContent from './components/sections/MainContent';
import Submitted from './components/sections/Submitted';
import SecureForm from './components/sections/SecureForm';

export default function App() {
	const [secureKey, setSecureKey] = useState('');

	return <div className='App'>
		<>
			<GpsGateTopNav/>
			<GpsGateMainMenu/>
		</>
		<Title>
			Securely submit your login information
		</Title>
		<MainContent>
			{secureKey && <Submitted secureKey={secureKey}/>}
			{!secureKey && <SecureForm setSecureKey={setSecureKey} />}
		</MainContent>
	</div>
}
